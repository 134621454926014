import { useState } from "react";

import styles from "./TeamStats.module.css";
import FilterSearch from "../components/FilterSearch";

export interface Stat {
  name: string;
  value: number;
  rank: number;
}

interface TeamRecord {
  wins: number;
  losses: number;
  bestW: number;
  avgW: number;
  avgL: number;
}

interface TeamStat {
  id: number;
  name: string;
  conferenceName: string;
  rank: number;
  vciScore: number;
  overall: TeamRecord;
  conf?: TeamRecord;
  away?: TeamRecord;
  home?: TeamRecord;
  betting: Stat[];
  core: Stat[];
  offense: Stat[];
  defense: Stat[];
}

type SortBy = {
  key: string;
  order: number;
  statType?: "overall" | "betting" | "core" | "offense" | "defense";
};

const TeamStats = () => {
  const [teamStats, setTeamStats] = useState<TeamStat[]>(fetchStats);
  const [category, setCategory] = useState<"offense" | "defense">("offense");
  const [sortBy, setSortBy] = useState<SortBy>({
    key: "rank",
    statType: undefined,
    order: 1,
  });
  const [searchTerm, setSearchTerm] = useState("");

  function fetchStats(): TeamStat[] {
    fetch("/data/teamstat-composite-active.json")
      .then((response) => response.json())
      .then((data) => {
        setTeamStats(data);
      })
      .catch((error) => {
        console.error("Error fetching team stats data:", error);
      });

    return [];
  }

  function setSortByState(
    key: string,
    statType?: "overall" | "betting" | "core" | "offense" | "defense",
    colOrder = 1
  ) {
    setSortBy((oldState) => {
      const order =
        oldState.key === key && oldState.statType === statType
          ? -1 * oldState.order
          : colOrder;
      const newState = { key: key, order: order, statType: statType };
      if (statType) newState.statType = statType;
      return newState;
    });
  }

  function getSortByClass(key: string, statType: string | undefined): string {
    if (key === sortBy.key && statType === sortBy.statType) {
      return `${styles.sortable} ${styles.selected}`;
    }
    return styles.sortable;
  }

  let filteredTeams: TeamStat[] = [];
  if (!searchTerm.trim()) {
    filteredTeams = teamStats;
  } else {
    const searchTerms = searchTerm
      .split(",")
      .map((term) => term.trim().toLowerCase())
      .filter(Boolean);
    const exactTerms: string[] = [];
    filteredTeams.push(
      ...teamStats.filter((team) =>
        searchTerms.some((term) => {
          if (
            team.name.toLowerCase() === term ||
            team.conferenceName.toLowerCase() === term
          ) {
            exactTerms.push(term);
            return true;
          }
          return false;
        })
      )
    );

    // now consider partials
    filteredTeams.push(
      ...teamStats.filter((team) =>
        searchTerms.some(
          (term) =>
            !exactTerms.includes(team.name.toLowerCase()) &&
            !exactTerms.includes(team.conferenceName.toLowerCase()) &&
            !exactTerms.includes(term) &&
            (team.name.toLowerCase().includes(term) ||
              team.conferenceName.toLowerCase().includes(term))
        )
      )
    );
  }

  filteredTeams.sort((a, b) => {
    if (sortBy.statType === "overall") {
      const aVal =
        a[sortBy.statType]![sortBy.key as keyof TeamRecord] ||
        Number.POSITIVE_INFINITY;
      const bVal =
        b[sortBy.statType]![sortBy.key as keyof TeamRecord] ||
        Number.POSITIVE_INFINITY;
      const diff = aVal - bVal;
      return diff * sortBy.order;
    } else if (sortBy.statType) {
      const aVal = a[sortBy.statType]!.find(
        (stat) => stat.name === sortBy.key
      )!.rank;
      const bVal = b[sortBy.statType]!.find(
        (stat) => stat.name === sortBy.key
      )!.rank;

      const diff = aVal - bVal;
      return diff * sortBy.order;
    } else {
      const aVal = a[sortBy.key as keyof TeamStat];
      const bVal = b[sortBy.key as keyof TeamStat];

      if (aVal! === bVal!) {
        // break tie by rank
        return a.rank - b.rank;
      }
      const diff = aVal! <= bVal! ? -1 : 1;
      return diff * sortBy.order;
    }
  });

  return (
    <>
      <div>
        <div className={styles.banner}>
          <h2>2023-24 Team Stats</h2>
          <FilterSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder="e.g. Duke, B12"
          />
        </div>
        <div className={styles.faqItem}>
          <div className={styles.question}>What is rank?</div>
          <div className={styles.answer}>
            Rank is the Vincerix Composite Index (VCI). It is a blend of
            computer generated rankings (Kenpom, Bart Torvik, NET, etc.), human
            polls (AP), our in-house power rankings, and NCAA futures data from
            10+ sportsbooks.
          </div>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.stickyTable}>
          <thead className={styles.stickyRow}>
            <tr>
              <th className={styles.stickyColumn}></th>
              <th colSpan={5}>Overall</th>
              <th colSpan={2}>Betting</th>
              <th colSpan={4}>Core</th>
              <th colSpan={11} className={styles.textLeft}>
                <span
                  onClick={() => setCategory("offense")}
                  className={`${
                    category === "offense" ? styles.selected : ""
                  } ${styles.sortable}`}
                >
                  Offense
                </span>
                <span
                  onClick={() => setCategory("defense")}
                  className={`${
                    category === "defense" ? styles.selected : ""
                  } ${styles.sortable}`}
                >
                  Defense
                </span>
              </th>
            </tr>
            <tr>
              <th
                className={`${styles.stickyColumn} ${styles.sortable}`}
                onClick={() => setSortByState(`rank`, undefined)}
              >
                TEAM
              </th>
              <th
                onClick={() => setSortByState("conferenceName", undefined)}
                className={getSortByClass("conferenceName", undefined)}
              >
                Conf
              </th>
              <th>W-L</th>
              <th
                title="Best win for a given team by current VCI of all opponents defeated"
                onClick={() => setSortByState("bestW", "overall")}
                className={getSortByClass("bestW", "overall")}
              >
                Best W
              </th>
              <th
                title="Average VCI rank of all opponents a given team has defeated"
                onClick={() => setSortByState("avgW", "overall")}
                className={getSortByClass("avgW", "overall")}
              >
                Avg W
              </th>
              <th
                title="Average VCI rank of all opponents a given team has lost to"
                onClick={() => setSortByState("avgL", "overall")}
                className={getSortByClass("avgL", "overall")}
              >
                Avg L
              </th>
              <th
                title="Number of times a team has covered the spread as favorite or underdog divided by the total number of games with a spread"
                onClick={() => setSortByState("Cover %", "betting")}
                className={getSortByClass("Cover %", "betting")}
              >
                Cover %
              </th>
              <th
                title="Number of times a team has had their game total go “over” divided by the total number of games with a total line"
                onClick={() => setSortByState("Over %", "betting")}
                className={getSortByClass("Over %", "betting")}
              >
                Over %
              </th>
              <th
                title="This is the weighted average score of the VCI components. The maximum value (ranked 1 in all VCI components) is 362, while the minimum score is 1. This is not by itself a predictive indicator, but rather a weighted compilation of metric ranks."
                onClick={() => setSortByState("vciScore", undefined, -1)}
                className={getSortByClass("vciScore", undefined)}
              >
                VCI Score
              </th>
              <th
                title="Average points scored per 100 possessions"
                onClick={() => setSortByState("OffEff", "core")}
                className={getSortByClass("OffEff", "core")}
              >
                OffEff
              </th>
              <th
                title="Average points allowed per 100 possessions"
                onClick={() => setSortByState("DefEff", "core")}
                className={getSortByClass("DefEff", "core")}
              >
                DefEff
              </th>
              <th
                onClick={() => setSortByState("Pace", "core")}
                className={getSortByClass("Pace", "core")}
              >
                Pace
              </th>
              {teamStats.length > 0 &&
                teamStats[0].offense.map((stat) => (
                  <th
                    key={stat.name}
                    onClick={() => setSortByState(stat.name, category)}
                    className={getSortByClass(stat.name, category)}
                  >
                    {stat.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {filteredTeams.map((team, index) => (
              <tr key={index}>
                <td className={`${styles.stickyColumn} ${styles.nameCol}`}>
                  <span
                    className={styles.subfont}
                    style={{ paddingRight: "5px" }}
                  >
                    {team.rank}
                  </span>
                  <span
                    className={styles.teamName}
                    style={{ paddingRight: "5px" }}
                  >
                    {team.name}
                  </span>
                </td>
                <td>{team.conferenceName}</td>
                <td>
                  {team.overall.wins}-{team.overall.losses}
                </td>
                <td>{team.overall.bestW || "-"}</td>
                <td>{team.overall.avgW || "-"}</td>
                <td>{team.overall.avgL || "-"}</td>
                {team.betting.map((stat) => (
                  <td
                    key={stat.name}
                    className={
                      stat.rank! <= 10
                        ? styles.better
                        : stat.rank! > teamStats.length - 10
                        ? styles.worse
                        : ""
                    }
                  >
                    {stat.value && <span>{stat.value.toFixed(1)}</span>}
                    {stat.rank && (
                      <span className={styles.subfont}> ({stat.rank})</span>
                    )}
                  </td>
                ))}
                <td
                  className={
                    team.rank! <= 10
                      ? styles.better
                      : team.rank! > teamStats.length - 10
                      ? styles.worse
                      : ""
                  }
                >
                  {team.vciScore.toFixed(1)}
                </td>
                {team.core.map((stat) => (
                  <td
                    key={stat.name}
                    className={
                      stat.rank! <= 10
                        ? styles.better
                        : stat.rank! > teamStats.length - 10
                        ? styles.worse
                        : ""
                    }
                  >
                    {stat.value && stat.value.toFixed(1)}
                    {stat.rank && (
                      <span className={styles.subfont}> ({stat.rank})</span>
                    )}
                  </td>
                ))}
                {team[category]!.map((stat) => (
                  <td
                    key={stat.name}
                    className={
                      stat.rank! <= 10
                        ? styles.better
                        : stat.rank! > teamStats.length - 10
                        ? styles.worse
                        : ""
                    }
                  >
                    {stat.value && stat.value.toFixed(1)}

                    {stat.rank && (
                      <span className={styles.subfont}> ({stat.rank})</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TeamStats;
