import { useState } from "react";
import { GameOdds, Matchup } from "../../constants/matchups";
import styles from "../../pages/Matchups.module.css";
import BestBookAvailable from "../BestBookAvailable";
import MatchupCard from "../MatchupCard/MatchupCard";
import { useTheme } from "../../contexts/ThemeContext";

interface TeamResult {
  type: string;
  record: string;
  recent: string;
  aggregate: number;
}

interface MatchupRowProps {
  selectedBooks: string[];
  matchup: Matchup;
  homeResults: TeamResult[];
  awayResults: TeamResult[];
}

const MatchupRow: React.FC<MatchupRowProps> = ({
  selectedBooks,
  matchup,
  homeResults,
  awayResults,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isGameFinal = Boolean(matchup.matchup.homeTotal);
  const { theme } = useTheme();

  matchup.matchup.homeTotal = Number(matchup.matchup.homeTotal);
  matchup.matchup.awayTotal = Number(matchup.matchup.awayTotal);
  const onRowClick = () => {
    setIsOpen((oldVal) => !oldVal);
  };
  const isWinner = (matchup: Matchup, teamScore: number) =>
    Math.max(matchup.matchup.awayTotal!, matchup.matchup.homeTotal!) ===
    teamScore;

  return (
    <div className={styles.rowContainer} onClick={onRowClick}>
      <div className={styles.matchupRow}>
        {/* Rank + Teams */}
        <table className={styles.table}>
          <thead>
            <tr>
              <th
                className={`${styles.textLeft} ${styles.doubleCell} ${styles.matchupHeader}`}
              >
                <span>
                  {isGameFinal
                    ? matchup.matchup.status
                    : new Date(matchup.matchup.matchTime).toLocaleTimeString(
                        undefined,
                        { hour: "numeric", minute: "numeric", hour12: true }
                      )}
                </span>

                <a
                  href={`/matchup/${matchup.matchup.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  In-Depth
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {[
              { ...matchup.awayTeam, score: matchup.matchup.awayTotal },
              { ...matchup.homeTeam, score: matchup.matchup.homeTotal },
            ].map((team) => (
              <tr key={team.id}>
                <td
                  className={`${styles.doubleCell} ${
                    isGameFinal && isWinner(matchup, team.score!)
                      ? styles.winner
                      : ""
                  } ${styles.grid}`}
                >
                  <span className={styles.subfont}>{team.rank || "-"} </span>
                  <div style={{ height: "20px", overflow: "hidden" }}>
                    <img
                      style={{ height: "20px" }}
                      className={styles.matchupLogo}
                      src={
                        team.rank
                          ? `https://cbb.vincerix.com/images/teams/${
                              theme === "light" ||
                              (isGameFinal && isWinner(matchup, team.score!))
                                ? ""
                                : "dark/"
                            }40/logo_${team.id}.png`
                          : "https://cbb.vincerix.com/images/teams/logo_default.png"
                      }
                      alt={`${team.name} logo`}
                    />
                  </div>

                  <span className={styles.teamName}>{team.name}</span>
                  {isGameFinal ? (
                    <span>{team.score}</span>
                  ) : (
                    <span className={styles.subfont}>{team.record}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Stats */}
        <table className={`${styles.table} ${styles.desktop}`}>
          <thead>
            <tr>
              {matchup.homeTeam.stats.map((statItem) => (
                <th key={statItem.name}>{statItem.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[matchup.awayTeam, matchup.homeTeam].map((team) => {
              return (
                <tr key={team.id}>
                  {team.stats.map((statItem) => (
                    <td key={statItem.name}>
                      {statItem.value ? statItem.value.toFixed(1) : "-"}
                      {statItem.value && (
                        <span className={styles.subfont}>
                          {" "}
                          ({statItem.rank})
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Odds */}
        {["spread", "moneyline", "total"].map((betType: string) => (
          <div className={styles.bestBookWrapper}>
            <BestBookAvailable
              key={betType}
              selectedBooks={selectedBooks}
              homeOdds={matchup.homeTeam.odds[betType as keyof GameOdds]}
              awayOdds={matchup.awayTeam.odds[betType as keyof GameOdds]}
              betType={betType}
              score={[matchup.matchup.awayTotal!, matchup.matchup.homeTotal!]}
            />
          </div>
        ))}
      </div>
      {isOpen && (
        <MatchupCard
          home={{
            id: matchup.homeTeam.id,
            name: matchup.homeTeam.name,
            results: homeResults,
          }}
          away={{
            id: matchup.awayTeam.id,
            name: matchup.awayTeam.name,
            results: awayResults,
          }}
        />
      )}
    </div>
  );
};

export default MatchupRow;
