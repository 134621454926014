export interface Book {
  name: string;
  color: string;
  major?: boolean;
  logoSlug: string;
  darkLogoSlug: string;
}

export const BOOKS: Book[] = [
  {
    name: "BetMGM",
    color: "rgb(191, 144, 0)",
    major: true,
    logoSlug: "/images/betmgm_logo.png",
    darkLogoSlug: "/images/betmgm_dark_logo.png",
  },
  {
    name: "Caesars",
    color: "rgb(11, 118, 29)",
    major: true,
    logoSlug: "/images/caesars_logo.webp",
    darkLogoSlug: "/images/caesars_dark_logo.png",
  },
  {
    name: "DraftKings",
    color: "rgb(147, 196, 125)",
    major: true,
    logoSlug: "/images/draftkings_logo.png",
    darkLogoSlug: "/images/draftkings_dark_logo.png",
  },
  {
    name: "ESPN BET",
    color: "#d00",
    major: true,
    logoSlug: "/images/espnbet_logo.png",
    darkLogoSlug: "/images/espnbet_dark_logo.png",
  },
  {
    name: "FanDuel",
    color: "rgb(11, 83, 148)",
    major: true,
    logoSlug: "/images/fanduel_logo.png",
    darkLogoSlug: "/images/fanduel_logo.png",
  },
  {
    name: "BetRivers",
    color: "rgb(29, 53, 91)",
    logoSlug: "/images/betrivers_logo.webp",
    darkLogoSlug: "/images/betrivers_dark_logo.png",
  },
  {
    name: "Circa Sports",
    color: "rgb(0, 0, 0)",
    logoSlug: "/images/circasports_logo.png",
    darkLogoSlug: "/images/circasports_dark_logo.png",
  },
  {
    name: "Fanatics",
    color: "rgb(229, 60, 46)",
    logoSlug: "/images/fanatics_logo.png",
    darkLogoSlug: "/images/fanatics_dark_logo.png",
  },
  {
    name: "Pinnacle",
    color: "rgb(7, 36, 67)",
    logoSlug: "/images/pinnacle_logo.png",
    darkLogoSlug: "/images/pinnacle_dark_logo.png",
  },
  {
    name: "Sporttrade",
    color: "rgb(0, 95, 54)",
    logoSlug: "/images/sporttrade_logo.png",
    darkLogoSlug: "/images/sporttrade_dark_logo.png",
  },
];
