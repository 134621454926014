import { BOOKS } from "../../constants/books";
import { Odds } from "../../constants/matchups";
import { useTheme } from "../../contexts/ThemeContext";
import styles from "../../pages/Matchups.module.css";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

interface BestBookAvailableProps {
  selectedBooks: string[];
  awayOdds: Odds[];
  homeOdds: Odds[];
  betType: string;
  score: number[];
  isVertical?: boolean;
  awayLogo?: string;
  homeLogo?: string;
}

const BestBookAvailable: React.FC<BestBookAvailableProps> = ({
  selectedBooks,
  awayOdds,
  homeOdds,
  betType,
  score,
  isVertical,
}) => {
  const { theme } = useTheme();

  function getCellColor(index: number, bestOddsItem: Odds) {
    if (score[0]) {
      switch (betType) {
        case "moneyline":
          return score[index] === Math.max(...score) ? styles.winner : "";
        case "spread":
          const adjScore = score[index] + bestOddsItem.value!;
          if (adjScore === score[index ^ 1]) return styles.push;
          return adjScore > score[index ^ 1] ? styles.winner : "";
        case "total":
          const totalAdjuster = -1 + 2 * index; // -1 for over, 1 for under
          const total = score.reduce((a, b) => a + b, 0);
          if (total === bestOddsItem.value) return styles.push;
          return totalAdjuster * score.reduce((a, b) => a + b, 0) <
            totalAdjuster * bestOddsItem.value!
            ? styles.winner
            : "";
        default:
          return "";
      }
    }
    return "";
  }

  const noOddsSymbol = "-";
  const oddsKey: keyof Odds = betType === "moneyline" ? "odds" : "value";

  const displayOdds = [awayOdds, homeOdds].map((teamOdds, index) => {
    const filteredOdds = teamOdds.filter((item) =>
      selectedBooks.includes(item.book)
    );

    if (filteredOdds.length === 0) {
      return {
        book: undefined,
        odds: undefined,
        val: noOddsSymbol,
      };
    }
    // the over is the only time we want the lowest value
    const totalAdjuster = betType === "total" && index === 0 ? -1 : 1;
    const bestOddsItem = filteredOdds.sort((a, b) => {
      const diff = totalAdjuster * (b[oddsKey]! - a[oddsKey]!);
      if (!diff) return b.odds - a.odds;
      return diff;
    })[0];
    const bestVal = bestOddsItem[oddsKey]!;
    const valPrefix =
      betType === "total" ? (index === 0 ? "O" : "U") : bestVal > 0 ? "+" : "";

    const highlight = getCellColor(index, bestOddsItem);
    return {
      book: BOOKS.filter((book) => book.name === bestOddsItem.book)[0],
      odds:
        betType === "moneyline"
          ? undefined
          : `${bestOddsItem.odds > 0 ? "+" : ""}${bestOddsItem.odds}`,
      val: `${valPrefix}${bestVal === 0 ? "PK" : bestVal}`,
      highlight: highlight,
    };
  });

  if (isVertical) {
    return (
      <>
        <div
          style={{
            width: "80%",
            maxWidth: "150px",
            justifySelf: "center",
            padding: "4px",
            borderRadius: displayOdds[0].book ? "8px" : "",
            boxShadow: displayOdds[0].book
              ? "0 2px 4px rgba(var(--color-rgb), 0.3)"
              : "",
            border: displayOdds[0].book ? "1px solid gray" : "",
          }}
        >
          <div
            style={{ borderRadius: "6px", padding: "0 3px" }}
            className={displayOdds[0].highlight}
          >
            <span>
              {displayOdds[0].val}
              {displayOdds[0].odds && (
                <span className={styles.subfont}> ({displayOdds[0].odds})</span>
              )}
            </span>
          </div>
          <div style={{ paddingTop: "4px" }}>
            {displayOdds[0].book ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className={styles.bookLogo}
                  src={
                    theme === "light"
                      ? displayOdds[0].book.logoSlug
                      : displayOdds[0].book.darkLogoSlug
                  }
                  alt={displayOdds[0].book.name}
                />
              </div>
            ) : (
              noOddsSymbol
            )}
          </div>
        </div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {capitalizeFirstLetter(betType)}
        </div>
        <div
          style={{
            width: "80%",
            maxWidth: "150px",
            justifySelf: "center",
            padding: "4px",
            borderRadius: displayOdds[1].book ? "8px" : "",
            boxShadow: displayOdds[1].book
              ? "0 2px 4px rgba(var(--color-rgb), 0.3)"
              : "",
            border: displayOdds[1].book ? "1px solid gray" : "",
          }}
        >
          <div
            style={{ borderRadius: "6px", padding: "0 3px" }}
            className={displayOdds[1].highlight}
          >
            <span>
              {displayOdds[1].val}
              {displayOdds[1].odds && (
                <span className={styles.subfont}> ({displayOdds[1].odds})</span>
              )}
            </span>
          </div>
          <div style={{ paddingTop: "4px" }}>
            {displayOdds[1].book ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className={styles.bookLogo}
                  src={
                    theme === "light"
                      ? displayOdds[1].book.logoSlug
                      : displayOdds[1].book.darkLogoSlug
                  }
                  alt={displayOdds[1].book.name}
                />
              </div>
            ) : (
              noOddsSymbol
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Best {capitalizeFirstLetter(betType)}</th>
        </tr>
      </thead>
      <tbody>
        {displayOdds.map((teamOdds, index) => (
          <tr key={index}>
            <td className={teamOdds.highlight}>
              <span>
                {teamOdds.val}
                {teamOdds.odds && (
                  <span className={styles.subfont}> ({teamOdds.odds})</span>
                )}
              </span>
            </td>
            <td
              style={{
                color: `${teamOdds.book ? teamOdds.book.color : "inherit"}`,
                fontWeight: "bold",
              }}
            >
              {teamOdds.book ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={styles.bookLogo}
                    src={
                      theme === "light"
                        ? teamOdds.book.logoSlug
                        : teamOdds.book.darkLogoSlug
                    }
                    alt={teamOdds.book.name}
                  />
                </div>
              ) : (
                noOddsSymbol
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BestBookAvailable;
