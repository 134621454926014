import { useEffect, useState } from "react";
import BookSelector from "../components/BookSelector";
import FilterSearch, { filter } from "../components/FilterSearch/FilterSearch";
import styles from "./Matchups.module.css";
import { Matchup } from "../constants/matchups";
import { Book, BOOKS } from "../constants/books";
import MatchupRow from "../components/MatchupRow";
import { useTheme } from "../contexts/ThemeContext";

// Function to get the stored selected books from localStorage
const getStoredSelectedBooks = (): string[] => {
  const storedSelectedBooks = localStorage.getItem("selectedBooks");
  return storedSelectedBooks ? JSON.parse(storedSelectedBooks) : [];
};

interface TeamResult {
  type: string;
  record: string;
  recent: string;
  aggregate: number;
}

const Matchups = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [bookOptions, setBookOptions] = useState<Book[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<string[]>(
    getStoredSelectedBooks()
  );
  const [matchups, setMatchups] = useState<Matchup[]>([]);
  const [teamResults, setTeamResults] = useState<{
    [key: number]: TeamResult[];
  }>({});
  const [headerInView, setHeaderInView] = useState(true);
  const [sortBy, setSortBy] = useState<"time" | "rank">("time");
  const [day, setDay] = useState<"today" | "tomorrow">("today");
  const { theme } = useTheme();

  const showMatchup = (matchup: Matchup) => {
    if (day === "tomorrow") {
      return matchup.awayTeam.rank && matchup.homeTeam.rank;
    }
    return (
      matchup.awayTeam.odds.spread.length > 0 &&
      matchup.homeTeam.odds.total.length > 0
    );
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(event.target.value as "time" | "rank");
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDay(event.target.value as "today" | "tomorrow");
  };

  const sortMatchups = (matchups: Matchup[]): Matchup[] => {
    const sortedData = [...matchups];

    const checkStatus = (a: Matchup, b: Matchup) => {
      if (
        a.matchup.status?.startsWith("Final") &&
        !b.matchup.status?.startsWith("Final")
      ) {
        return 1;
      } else if (
        !a.matchup.status?.startsWith("Final") &&
        b.matchup.status?.startsWith("Final")
      ) {
        return -1;
      }
      return 0;
    };

    if (sortBy === "time") {
      sortedData.sort((a, b) => {
        const statusDiff = checkStatus(a, b);
        if (statusDiff !== 0) return statusDiff;
        return (
          new Date(a.matchup.matchTime).getTime() -
          new Date(b.matchup.matchTime).getTime()
        );
      });
    } else if (sortBy === "rank") {
      sortedData.sort((a, b) => {
        const statusDiff = checkStatus(a, b);
        if (statusDiff !== 0) return statusDiff;
        return (
          (a.awayTeam.rank || 363) +
          (a.homeTeam.rank || 363) -
          ((b.awayTeam.rank || 363) + (b.homeTeam.rank || 363))
        );
      });
    }
    return sortedData;
  };

  useEffect(() => {
    setMatchups((old) => sortMatchups(old));
  }, [sortBy]);

  // Function to check if the header is in view
  const checkHeaderInView = () => {
    const header = document.querySelector(".headerContainer");
    if (header) {
      const rect = header.getBoundingClientRect();
      setHeaderInView(rect.bottom >= 0);
    }
  };

  // Save selectedBooks to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedBooks", JSON.stringify(selectedBooks));
  }, [selectedBooks]);

  useEffect(() => {
    const suffix = day === "today" ? "active" : "tomorrow";
    fetch(`/data/matchup-odds-${suffix}.json`)
      .then((response) => response.json())
      .then((data) => {
        setMatchups(sortMatchups(data["matchups"]));
        const filteredBooks = BOOKS.filter((book) =>
          data["books"].includes(book.name)
        );
        setBookOptions(filteredBooks);
        if (selectedBooks.length === 0) {
          // Set selectedBooks to filteredBooks if it's empty (first visit)
          setSelectedBooks(filteredBooks.map((book) => book.name));
        }
      })
      .catch((error) => console.error("Error fetching matchups data:", error));
  }, [day]);

  useEffect(() => {
    const fetchData = async (date: Date) => {
      try {
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "America/Los_Angeles",
        };
        const formattedDate = date
          .toLocaleString("ja-JP", options) // ja-JP does yyyy-mm-dd
          .replace(/\//g, "-");

        const response = await fetch(
          `/data/matchups-page/card/matchup-odds-card-${formattedDate}.json`
        );
        const data = await response.json();
        setTeamResults(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (matchups.length > 0) {
      fetchData(new Date(matchups[0].matchup.matchTime));
    }
  }, [matchups]);

  useEffect(() => {
    // Check header visibility on initial load and scroll events
    checkHeaderInView();
    window.addEventListener("scroll", checkHeaderInView);

    return () => {
      // Clean up the scroll event listener when the component unmounts
      window.removeEventListener("scroll", checkHeaderInView);
    };
  }, []);

  // Preload book images
  useEffect(() => {
    const preloadImages = async () => {
      const images = bookOptions.map((book) => {
        const slug = theme === "light" ? book.logoSlug : book.darkLogoSlug;
        if (slug) {
          const image = new Image();
          image.src = slug;
          return image;
        }
        return null;
      });

      await Promise.all(images);
    };

    preloadImages();
  }, [bookOptions]);

  const filteredMatchups = filter(matchups, searchTerm);
  return (
    <>
      <div className={styles.stickyTop}>
        <div className={styles.dynamicFlex}>
          <div
            style={{
              display: "block",
              textAlign: "left",
            }}
          >
            {headerInView ? ( // Conditionally render based on header visibility
              <h2>{`Matchups${
                matchups.length > 0
                  ? ` - ${new Date(
                      matchups[0].matchup.matchTime
                    ).toLocaleDateString(undefined, {})}`
                  : ""
              }`}</h2>
            ) : (
              <h2>
                <a href="https://cbb.vincerix.com" className={styles.logoLink}>
                  <img
                    src={`/images/vincerix-${theme}-small.png`}
                    alt="vincerix"
                  />
                </a>
              </h2>
            )}
          </div>

          <div className={styles.filterOptions}>
            <div className={styles.paddingRight}>
              <select name="sort" value={day} onChange={handleDayChange}>
                <option value="today">Today</option>
                <option value="tomorrow">Tomorrow</option>
              </select>
            </div>
            <div className={styles.paddingRight}>
              <select name="sort" value={sortBy} onChange={handleSortChange}>
                <option value="time">Time</option>
                <option value="rank">Ranks</option>
              </select>
            </div>
            <div className={styles.paddingRight}>
              <FilterSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
            <div style={{ minWidth: "150px" }}>
              <BookSelector
                options={bookOptions}
                selectedBooks={selectedBooks}
                setSelectedBooks={setSelectedBooks}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.matchupsContainer}>
        {filteredMatchups.map(
          (matchup) =>
            // only show games with betting data
            showMatchup(matchup) && (
              <MatchupRow
                key={matchup.matchup.awayTeamId}
                selectedBooks={selectedBooks}
                matchup={matchup}
                homeResults={teamResults[matchup.matchup.homeTeamId]}
                awayResults={teamResults[matchup.matchup.awayTeamId]}
              />
            )
        )}
      </div>
    </>
  );
};

export default Matchups;
