import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

type Theme = "light" | "dark";

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light");

  const toggleTheme = () => {
    const newTheme: Theme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.documentElement.dataset.theme = newTheme;
    localStorage.setItem("mode", newTheme);
  };

  useEffect(() => {
    // Team decided default to light on first visit instead of system
    const fallback = "light";
    const storedMode = localStorage.getItem("mode") || fallback;
    let calculatedTheme: Theme;

    if (storedMode === "system") {
      const isSystemInDarkMode = matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      calculatedTheme = isSystemInDarkMode ? "dark" : "light";
    } else {
      calculatedTheme = storedMode as Theme;
    }

    // Set the theme in the state
    setTheme(calculatedTheme);
    // Set the theme in the HTML dataset
    document.documentElement.dataset.theme = calculatedTheme;
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
