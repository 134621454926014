import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";

const Footer = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderTop: "2px solid",
        padding: "10px",
        marginTop: "5px",
        fontWeight: "bold",
        fontSize: "small",
      }}
    >
      <Link style={{ color: "inherit" }} to="https://vincerix.com/about">
        About
      </Link>
      <Link
        style={{ color: "inherit" }}
        to="mailto:hello@vincerix.com?subject=Hello&=undefined"
      >
        Contact Us
      </Link>
      <Link
        style={{ color: "inherit" }}
        to="https://vincerix.com/site-terms-of-use"
      >
        Website Terms of Use
      </Link>
      <button onClick={toggleTheme}>
        {theme === "dark" ? "Light" : "Dark"} Mode
      </button>
    </div>
  );
};

export default Footer;
