import React from "react";
import styles from "../../pages/Matchups.module.css";
import cardStyles from "./MatchupCard.module.css";

interface ColoredResultProps {
  char: string;
}
const ColoredResult: React.FC<ColoredResultProps> = ({ char }) => {
  let color = "inherit";
  if (char === "W" || char === "O") color = "#79d279";
  else if (char === "L" || char === "U") color = "#ff6666ee";
  return <span style={{ color: color, fontWeight: "bold" }}>{char}</span>;
};

export interface BetResult {
  type: string;
  record: string;
  recent: string;
  aggregate: number;
}

interface TeamCard {
  id: number;
  name: string;
  results: BetResult[];
}

interface MatchupCardProps {
  home: TeamCard;
  away: TeamCard;
}

const cardTables = ["ATS", "ML", "Total"];

function oddsToString(val: number, betType: string): string {
  const prefix =
    betType !== "total" ? (val > 0 ? "+" : val < 0 ? "-" : "") : "";
  if (betType === "moneyline" || betType === "home" || betType === "away") {
    return prefix + Math.abs(val).toFixed(0).padStart(3, "0");
  }
  return prefix + Math.abs(val).toFixed(1);
}

const MatchupCard: React.FC<MatchupCardProps> = ({ home, away }) => {
  home.results = home.results
    ? home.results.filter(
        (result) => result.type !== "away" && result.type !== "home"
      )
    : home.results;
  away.results = away.results
    ? away.results.filter(
        (result) => result.type !== "home" && result.type !== "away"
      )
    : away.results;
  return (
    <div className={`${styles.matchupRow} ${cardStyles.cardRow}`}>
      <table className={`${styles.table} ${cardStyles.nameTable}`}>
        <thead>
          <tr>
            <th>Team Trends</th>
          </tr>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className={styles.doubleCell}
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {away.name}
            </td>
          </tr>
          <tr>
            <td
              className={styles.doubleCell}
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {home.name}
            </td>
          </tr>
        </tbody>
      </table>
      {cardTables.map((tableName, index) => (
        <table className={`${styles.table} ${cardStyles.cardTable}`}>
          <thead>
            <tr>
              <th colSpan={3}>{tableName}</th>
            </tr>
            <tr>
              <th>L5</th>
              <th>Season</th>
              <th>Avg</th>
            </tr>
          </thead>
          <tbody>
            {[away, home].map((team) =>
              team.results ? (
                <tr>
                  <td>
                    {team.results[index].recent.split("").map((char, index) => (
                      <ColoredResult key={index} char={char} />
                    ))}
                  </td>
                  <td>{team.results[index].record}</td>
                  <td>
                    {team.results[index].aggregate !== null
                      ? oddsToString(
                          team.results[index].aggregate,
                          team.results[index].type
                        )
                      : "-"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default MatchupCard;
