import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Matchups from "./pages/Matchups";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TeamStats from "./pages/TeamStats";
import DetailedMatchup from "./pages/DetailedMatchup";
import { ThemeProvider } from "./contexts/ThemeContext";

const getStoredUserInfo = () => {
  const storedUserInfo = localStorage.getItem("userInfo");
  return storedUserInfo ? JSON.parse(storedUserInfo) : null;
};

function App() {
  const [userInfo, setUserInfo] = useState<{
    email: string;
    dateJoined: number;
  } | null>(getStoredUserInfo());
  const [showThankYou, setShowThankYou] = useState(false);

  const handleEmailSubmit = async (email: string) => {
    setShowThankYou(true);
    setTimeout(() => {
      setShowThankYou(false);
    }, 2000);

    try {
      const response = await fetch("/auth", {
        method: "POST",
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        const newUserInfo = { email, dateJoined: data.dateJoined };
        setUserInfo(newUserInfo);
        localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      } else {
        console.log("Error from server");
      }
    } catch (error) {
      console.error("Error submitting email", error);
    }
  };

  return (
    <ThemeProvider>
      <div className="App">
        <BrowserRouter>
          <Header />
          <div className="content">
            <Routes>
              <Route path="/" element={<Matchups />} />
              <Route path="/stats" element={<TeamStats />} />
              {userInfo === null || showThankYou ? (
                <Route
                  path="*"
                  element={
                    showThankYou ? (
                      <h3>Thank you for being a valued Vincerix user!</h3>
                    ) : (
                      <EmailForm onSubmit={handleEmailSubmit} />
                    )
                  }
                />
              ) : (
                <Route
                  path="/matchup/:matchupId"
                  element={<DetailedMatchup />}
                />
              )}
              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

interface EmailFormProps {
  onSubmit: (email: string) => void;
}

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <>
      <h2>
        Please first register your email address to help our young company track
        user count!
      </h2>
      <h4>
        We will remember your email in the future when you use this browser.
      </h4>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default App;
