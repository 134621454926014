import { Matchup } from "../../constants/matchups";
import styles from "./FilterSearch.module.css";

interface FilterSearchProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
}
const FilterSearch = (props: FilterSearchProps) => {
  return (
    <div className={styles.searchWrapper}>
      <input
        id="search-box"
        type="text"
        value={props.searchTerm}
        onChange={(e) => props.setSearchTerm(e.target.value)}
        placeholder={props.placeholder || "e.g. Duke, UCLA"}
      />
    </div>
  );
};

// TODO: make this generic typing
export function filter(matchups: Matchup[], searchString: string) {
  const searchTerms = searchString
    .split(",")
    .map((term) => term.trim().toLowerCase())
    .filter(Boolean); // This will remove any empty or whitespace-only terms
  let filteredArr: Matchup[] = [];
  if (!searchString.trim()) {
    return matchups;
  } else {
    // check for exact matches
    const exactTerms: string[] = [];
    filteredArr.push(
      ...matchups.filter((matchup) =>
        searchTerms.some((term) => {
          if (
            matchup.awayTeam.name.toLowerCase() === term ||
            matchup.homeTeam.name.toLowerCase() === term
          ) {
            exactTerms.push(term);
            return true;
          }
          return false;
        })
      )
    );

    // now consider partials
    filteredArr.push(
      ...matchups.filter((matchup) =>
        searchTerms.some(
          (term) =>
            !exactTerms.includes(term) &&
            (matchup.awayTeam.name.toLowerCase().includes(term) ||
              matchup.homeTeam.name.toLowerCase().includes(term))
        )
      )
    );
  }
  return filteredArr;
}

export default FilterSearch;
