import { useEffect, useRef, useState } from "react";
import styles from "./BookSelector.module.css";
import { Book } from "../../constants/books";
import { useTheme } from "../../contexts/ThemeContext";

export interface BookSelectorProps {
  /**
   * Book options that contain book display name, color, etc.
   * This is always a complete set of possible books.
   */
  options: Book[];
  /**
   * User selected books where items equal book.name from `options`.
   * Needed in this component to indicate which options are selected.
   */
  selectedBooks: string[];
  /**
   * Function to update parent's selectedBooks state.
   * Parent needs to control this state because it's used in a peer component.
   */
  setSelectedBooks: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * @returns React component that is essentially a multi-select feature.
 * This specific component is used for selecting which sportsbook the
 * user wants to see odds for.
 */
const BookSelector: React.FC<BookSelectorProps> = ({
  options,
  selectedBooks,
  setSelectedBooks,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Toggle the option in selected state
   * @param option clicked option from dropdown list
   */
  const handleOptionClick = (option: Book) => {
    if (selectedBooks.includes(option.name)) {
      setSelectedBooks(selectedBooks.filter((item) => item !== option.name));
    } else {
      setSelectedBooks([...selectedBooks, option.name]);
    }
  };

  /**
   * Add listener that handles closing the book selector when a click is outside it
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.multiSelectDropdown} ref={dropdownRef}>
      <div
        className={`${styles.dropdownHeader} ${isOpen ? styles.isOpen : ""}`}
        onClick={toggleDropdown}
      >
        Sportsbooks
      </div>

      {isOpen && (
        <div className={styles.dropdownOptions}>
          {options.map((option) => (
            <div
              key={option.name}
              className={`${styles.option} ${
                selectedBooks.includes(option.name) && styles.selected
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.logoSlug ? (
                <img
                  className={styles.bookLogo}
                  src={
                    theme === "light" ? option.logoSlug : option.darkLogoSlug
                  }
                  alt={option.name}
                ></img>
              ) : (
                <div
                  className={styles.optionName}
                  style={{
                    backgroundColor: option.color || "black",
                  }}
                >
                  {option.name}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BookSelector;
