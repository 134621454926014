import { useEffect, useState } from "react";
import BookSelector from "../components/BookSelector/BookSelector";
import { BOOKS } from "../constants/books";
import BestBookAvailable from "../components/BestBookAvailable/BestBookAvailable";
import { GameOdds, Odds } from "../constants/matchups";

import styles from "./DetailedMatchup.module.css";
import { Bar, Line, Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Chart,
} from "chart.js";
import { useParams } from "react-router-dom";
import { BetResult } from "../components/MatchupCard/MatchupCard";
import { useTheme } from "../contexts/ThemeContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

interface ColoredResultProps {
  char: string;
}
const ColoredResult: React.FC<ColoredResultProps> = ({ char }) => {
  let color = "inherit";
  if (char === "W" || char === "O") color = "#79d279";
  else if (char === "L" || char === "U") color = "#ff6666ee";
  return <span style={{ color: color, fontWeight: "bold" }}>{char}</span>;
};

function oddsToString(val: number, betType: string): string {
  const prefix =
    betType !== "total" ? (val > 0 ? "+" : val < 0 ? "-" : "") : "";
  if (betType === "moneyline" || betType === "home" || betType === "away") {
    return prefix + Math.abs(val).toFixed(0).padStart(3, "0");
  }
  return prefix + Math.abs(val).toFixed(1);
}

function getEmoji(
  offenseRank: number,
  defenseRank: number,
  stat: string
): string {
  if (!offenseRank || !defenseRank) return "";
  if (Math.max(offenseRank, defenseRank) <= 40) return "🔥";
  else if (Math.abs(offenseRank - defenseRank) >= 300 && stat.includes("%"))
    return "☠️";
  else if (
    Math.abs(offenseRank - defenseRank) >= 100 &&
    Math.min(offenseRank, defenseRank) <= 40
  )
    return "🚨";
  return "";
}

const emojiDescription: Record<string, string> = {
  "🚨": "Tier 1 with 100+ rank difference",
  "☠️": "300+ rank difference",
  "🔥": "Tier 1 matchup",
};

interface Stat {
  name: string;
  value: number;
  rank: number;
}

interface TeamOverview {
  id: number;
  name: string;
  abbrev: string;
  rank: number;
  hexCode: string;
  record: string;
  teamRecord?: {
    record: string;
    bestW: number;
    worstL: number;
    avgW: number;
    avgL: number;
  };
  vciQuad: {
    quadOne: string;
    quadTwo: string;
    quadThree: string;
    quadFour: string;
  };
  stats?: Stat[];
  total: number;
}

interface GameLog {
  date: string;
  oppId: number;
  isHome: boolean;
  isNeutral: boolean;
  commonOpp: boolean;
  points: number;
  oppPoints: number;
  spread: number;
}

interface LineMovement {
  book: string;
  spread: number[];
  total: number[];
  moneyline: number[];
}

interface HistogramBucket {
  bucket: string;
  games: number;
  wins: number;
}

interface IDetailedMatchup {
  matchId: number;
  venue: string;
  date: string;
  status: string;
  isNeutral: boolean;
  overview: {
    awayTeam: TeamOverview;
    homeTeam: TeamOverview;
  };
  opponents: Record<string, { name: string; rank: number }>;
  gameLog: {
    awayTeam: GameLog[];
    homeTeam: GameLog[];
  };
  keyStats: {
    awayTeam: {
      offense: Stat[];
      defense: Stat[];
    };
    homeTeam: {
      offense: Stat[];
      defense: Stat[];
    };
  };
  lines: {
    awayTeam: GameOdds;
    homeTeam: GameOdds;
  };
  lineMovement: {
    awayTeam: LineMovement[];
    homeTeam: LineMovement[];
  };
  bettingForm: {
    awayTeam: BetResult[];
    homeTeam: BetResult[];
  };
  bettingHistogram: {
    awayTeam: {
      spread: HistogramBucket[];
      total: HistogramBucket[];
      moneyline: HistogramBucket[];
    };
    homeTeam: {
      spread: HistogramBucket[];
      total: HistogramBucket[];
      moneyline: HistogramBucket[];
    };
  };
}

// Function to get the stored selected books from localStorage
const getStoredSelectedBooks = (): string[] => {
  const storedSelectedBooks = localStorage.getItem("selectedBooks");
  return storedSelectedBooks ? JSON.parse(storedSelectedBooks) : [];
};

const DetailedMatchup = () => {
  const { matchupId } = useParams();
  const [data, setData] = useState<IDetailedMatchup | undefined>(fetchData);
  const [showQuads, setShowQuads] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState(getStoredSelectedBooks());
  const [coverType, setCoverType] = useState<"spread" | "moneyline" | "total">(
    "spread"
  );
  const [lineMovementTeam, setLineMovementTeam] = useState<{
    team: "awayTeam" | "homeTeam";
    betType: "spread" | "moneyline" | "total";
  }>({ team: "awayTeam", betType: "spread" });
  const { theme } = useTheme();
  ChartJS.defaults.color = theme === "dark" ? "white" : "black";
  ChartJS.defaults.borderColor = theme === "dark" ? "gray" : "lightgray";

  function fetchData(): IDetailedMatchup | undefined {
    // TODO: align with file naming convention
    const matchupFile = `/data/matchups/matchup-${matchupId}.json`;
    fetch(matchupFile)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        if (selectedBooks.length === 0) {
          setSelectedBooks(
            data.lines.homeTeam.spread.map((line: Odds) => line.book)
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching team stats data:", error);
      });

    return undefined;
  }

  // Save selectedBooks to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedBooks", JSON.stringify(selectedBooks));
  }, [selectedBooks]);

  if (!data) {
    return <></>;
  }

  const isGameFinal = data.status.startsWith("Final");

  return (
    <div
      className={styles.pageGrid}
      style={{
        display: "grid",
      }}
    >
      <div className={styles.leftCol}>
        <div id="overview">
          <div
            style={{
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            <span>
              {new Date(data.date).toLocaleTimeString(undefined, {
                timeStyle: "short",
              })}{" "}
              |{" "}
              {new Date(data.date).toLocaleDateString(undefined, {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </span>
            <div>{data.venue}</div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 0.25fr 1fr",
              alignItems: "center",
            }}
          >
            {/* Teams and Stats 3 column grid */}
            <div>
              <div style={{ position: "relative" }}>
                <img
                  className={styles.matchupLogo}
                  src={
                    data.overview.awayTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.awayTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "https://cbb.vincerix.com/images/teams/logo_default.png";
                  }}
                  alt={`${data.overview.awayTeam.name} logo`}
                />
                {data.overview.awayTeam.rank && (
                  <h2
                    style={{
                      position: "absolute",
                      left: "75%",
                      bottom: "0",
                      margin: "unset",
                    }}
                  >
                    #{data.overview.awayTeam.rank}
                  </h2>
                )}
              </div>
            </div>
            <span></span>
            <div>
              <div style={{ position: "relative" }}>
                <img
                  className={styles.matchupLogo}
                  src={
                    data.overview.homeTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.homeTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  alt={`${data.overview.homeTeam.name} logo`}
                />
                {data.overview.homeTeam.rank && (
                  <h2
                    style={{
                      position: "absolute",
                      right: "75%",
                      bottom: "0",
                      margin: "unset",
                    }}
                  >
                    #{data.overview.homeTeam.rank}
                  </h2>
                )}
              </div>
            </div>
            <div>
              <h2 style={{ marginTop: "unset" }}>
                {data.overview.awayTeam.name}
              </h2>
            </div>
            <span></span>
            <div>
              <h2 style={{ marginTop: "unset" }}>
                {data.overview.homeTeam.name}
              </h2>
            </div>
            {isGameFinal && (
              <>
                <h1 style={{ marginTop: "unset" }}>
                  {data.overview.awayTeam.total}
                </h1>
                <div>
                  <strong>{isGameFinal && data.status}</strong>
                </div>
                <h1 style={{ marginTop: "unset" }}>
                  {data.overview.homeTeam.total}
                </h1>
              </>
            )}
            <span
              style={{
                fontWeight: "bold",
                textDecoration: showQuads ? "underline" : "",
              }}
            >
              {data.overview.awayTeam.teamRecord?.record || "-"}
            </span>
            <span
              style={{
                cursor: "pointer",
                border: "1px solid lightgray",
                fontWeight: "bold",
                borderRadius: "9px",
                boxShadow: `${
                  showQuads ? "inset" : ""
                } 0 1px 1px rgba(0,0,0,0.2)`,
              }}
              onClick={() => setShowQuads((prev) => !prev)}
            >
              W/L
            </span>
            <span
              style={{
                fontWeight: "bold",
                textDecoration: showQuads ? "underline" : "",
              }}
            >
              {data.overview.homeTeam.teamRecord?.record || "-"}
            </span>
            {showQuads && (
              <>
                <span>{data.overview.awayTeam.vciQuad.quadOne}</span>
                <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  Quad 1
                </span>
                <span>{data.overview.homeTeam.vciQuad.quadOne}</span>
                <span>{data.overview.awayTeam.vciQuad.quadTwo}</span>
                <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  Quad 2
                </span>
                <span>{data.overview.homeTeam.vciQuad.quadTwo}</span>
                <span>{data.overview.awayTeam.vciQuad.quadThree}</span>
                <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  Quad 3
                </span>
                <span>{data.overview.homeTeam.vciQuad.quadThree}</span>
                <span>{data.overview.awayTeam.vciQuad.quadFour}</span>
                <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  Quad 4
                </span>
                <span>{data.overview.homeTeam.vciQuad.quadFour}</span>
              </>
            )}
            <div>
              {data.overview.awayTeam.stats?.[0].value ? (
                <>
                  {data.overview.awayTeam.stats[0].value.toFixed(1)}
                  <span className={styles.subText}>
                    {" "}
                    ({data.overview.awayTeam.stats[0].rank})
                  </span>
                </>
              ) : (
                "-"
              )}
            </div>
            <span style={{ fontWeight: "bold" }}>OffEff</span>
            <div>
              {data.overview.homeTeam.stats?.[0].value ? (
                <>
                  {data.overview.homeTeam.stats[0].value.toFixed(1)}
                  <span className={styles.subText}>
                    {" "}
                    ({data.overview.homeTeam.stats[0].rank})
                  </span>
                </>
              ) : (
                "-"
              )}
            </div>
            <div>
              {data.overview.awayTeam.stats?.[1].value ? (
                <>
                  {data.overview.awayTeam.stats[1].value.toFixed(1)}
                  <span className={styles.subText}>
                    {" "}
                    ({data.overview.awayTeam.stats[1].rank})
                  </span>
                </>
              ) : (
                "-"
              )}
            </div>
            <span style={{ fontWeight: "bold" }}>DefEff</span>
            <div>
              {data.overview.homeTeam.stats?.[1].value ? (
                <>
                  {data.overview.homeTeam.stats[1].value.toFixed(1)}
                  <span className={styles.subText}>
                    {" "}
                    ({data.overview.homeTeam.stats[1].rank})
                  </span>
                </>
              ) : (
                "-"
              )}
            </div>
            <div>
              <span style={{ borderBottom: "1px solid" }}>
                {data.overview.awayTeam.stats?.[2].value ? (
                  <>
                    {data.overview.awayTeam.stats[2].value.toFixed(1)}
                    <span className={styles.subText}>
                      {" "}
                      ({data.overview.awayTeam.stats[2].rank})
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </span>
            </div>
            <span style={{ fontWeight: "bold", borderBottom: "1px solid" }}>
              Pace
            </span>
            <div>
              <span style={{ borderBottom: "1px solid" }}>
                {data.overview.homeTeam.stats?.[2].value ? (
                  <>
                    {data.overview.homeTeam.stats[2].value.toFixed(1)}
                    <span className={styles.subText}>
                      {" "}
                      ({data.overview.homeTeam.stats[2].rank})
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </span>
            </div>
            {/* BestW/L */}
            <div>
              {data.overview.awayTeam.teamRecord?.bestW
                ? `#${data.overview.awayTeam.teamRecord.bestW}`
                : "-"}
            </div>
            <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
              Best W
            </span>
            <div>
              {data.overview.homeTeam.teamRecord?.bestW
                ? `#${data.overview.homeTeam.teamRecord.bestW}`
                : "-"}
            </div>
            <div>
              {data.overview.awayTeam.teamRecord?.worstL
                ? `#${data.overview.awayTeam.teamRecord.worstL}`
                : "-"}
            </div>
            <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
              Worst L
            </span>
            <div>
              {data.overview.homeTeam.teamRecord?.worstL
                ? `#${data.overview.homeTeam.teamRecord.worstL}`
                : "-"}
            </div>
            <div>
              {data.overview.awayTeam.teamRecord?.avgW
                ? `#${data.overview.awayTeam.teamRecord.avgW}`
                : "-"}
            </div>
            <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
              Avg W
            </span>
            <div>
              {data.overview.homeTeam.teamRecord?.avgW
                ? `#${data.overview.homeTeam.teamRecord.avgW}`
                : "-"}
            </div>
            <div>
              {data.overview.awayTeam.teamRecord?.avgL
                ? `#${data.overview.awayTeam.teamRecord.avgL}`
                : "-"}
            </div>
            <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
              Avg L
            </span>
            <div>
              {data.overview.homeTeam.teamRecord?.avgL
                ? `#${data.overview.homeTeam.teamRecord.avgL}`
                : "-"}
            </div>
          </div>
        </div>
        <div id="key-stats">
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            Key Stats
          </div>
          <div
            className={styles.keyStats}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1.3rem auto 1.3rem 1fr 1fr",
              alignItems: "center",
              margin: "0 10px",
            }}
          >
            <>
              <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                {data.overview.awayTeam.abbrev ||
                  data.overview.awayTeam.name
                    .substring(0, 5)
                    .trim()
                    .toUpperCase()}{" "}
                O
              </span>
              <span style={{ fontWeight: "bold" }}>
                {data.overview.homeTeam.abbrev ||
                  data.overview.homeTeam.name
                    .substring(0, 5)
                    .trim()
                    .toUpperCase()}{" "}
                D
              </span>
              <span></span>
              <span></span>
              <span></span>
              <span style={{ fontWeight: "bold" }}>
                {data.overview.homeTeam.abbrev ||
                  data.overview.homeTeam.name
                    .substring(0, 5)
                    .trim()
                    .toUpperCase()}{" "}
                O
              </span>
              <span style={{ fontWeight: "bold" }}>
                {data.overview.awayTeam.abbrev ||
                  data.overview.awayTeam.name
                    .substring(0, 5)
                    .trim()
                    .toUpperCase()}{" "}
                D
              </span>
            </>
            {data.keyStats.awayTeam.offense.map((awayOffenseStat, index) => {
              return (
                <div
                  className={styles.statContainer}
                  key={awayOffenseStat.name}
                >
                  <div
                    className={`${styles.statLeft} ${
                      awayOffenseStat.rank === null
                        ? styles.neutral
                        : awayOffenseStat.rank <= 40
                        ? styles.better
                        : awayOffenseStat.rank < 80
                        ? styles.good
                        : awayOffenseStat.rank < 120
                        ? styles.okay
                        : awayOffenseStat.rank < 160
                        ? styles.fine
                        : awayOffenseStat.rank < 200
                        ? styles.neutral
                        : awayOffenseStat.rank < 240
                        ? styles.notGood
                        : awayOffenseStat.rank < 280
                        ? styles.notOkay
                        : awayOffenseStat.rank < 320
                        ? styles.bad
                        : styles.worse
                    }`}
                  >
                    {awayOffenseStat.value ? (
                      <>
                        {awayOffenseStat.value &&
                          awayOffenseStat.value.toFixed(1)}
                        <span className={styles.subText}>
                          {" "}
                          ({awayOffenseStat.rank})
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div
                    className={`${styles.statRight} ${
                      data.keyStats.homeTeam.defense[index].rank === null
                        ? styles.neutral
                        : data.keyStats.homeTeam.defense[index].rank <= 40
                        ? styles.better
                        : data.keyStats.homeTeam.defense[index].rank < 80
                        ? styles.good
                        : data.keyStats.homeTeam.defense[index].rank < 120
                        ? styles.okay
                        : data.keyStats.homeTeam.defense[index].rank < 160
                        ? styles.fine
                        : data.keyStats.homeTeam.defense[index].rank < 200
                        ? styles.neutral
                        : data.keyStats.homeTeam.defense[index].rank < 240
                        ? styles.notGood
                        : data.keyStats.homeTeam.defense[index].rank < 280
                        ? styles.notOkay
                        : data.keyStats.homeTeam.defense[index].rank < 320
                        ? styles.bad
                        : styles.worse
                    }
                    }`}
                  >
                    {data.keyStats.homeTeam.defense[index].value ? (
                      <>
                        {data.keyStats.homeTeam.defense[index].value.toFixed(1)}
                        <span className={styles.subText}>
                          {" "}
                          ({data.keyStats.homeTeam.defense[index].rank})
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div
                    className={styles.hoverWrapper}
                    style={{ position: "relative" }}
                  >
                    {getEmoji(
                      awayOffenseStat.rank,
                      data.keyStats.homeTeam.defense[index].rank,
                      awayOffenseStat.name
                    )}
                    <span className={styles.popup}>
                      {
                        emojiDescription[
                          getEmoji(
                            awayOffenseStat.rank,
                            data.keyStats.homeTeam.defense[index].rank,
                            awayOffenseStat.name
                          )
                        ]
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {awayOffenseStat.name}
                  </div>
                  <div
                    className={styles.hoverWrapper}
                    style={{ position: "relative" }}
                  >
                    {getEmoji(
                      data.keyStats.homeTeam.offense[index].rank,
                      data.keyStats.awayTeam.defense[index].rank,
                      awayOffenseStat.name
                    )}
                    <span className={styles.popup}>
                      {
                        emojiDescription[
                          getEmoji(
                            data.keyStats.homeTeam.offense[index].rank,
                            data.keyStats.awayTeam.defense[index].rank,
                            awayOffenseStat.name
                          )
                        ]
                      }
                    </span>
                  </div>
                  <div
                    className={`${styles.statLeft} ${
                      data.keyStats.homeTeam.offense[index].rank === null
                        ? styles.neutral
                        : data.keyStats.homeTeam.offense[index].rank <= 40
                        ? styles.better
                        : data.keyStats.homeTeam.offense[index].rank < 80
                        ? styles.good
                        : data.keyStats.homeTeam.offense[index].rank < 120
                        ? styles.okay
                        : data.keyStats.homeTeam.offense[index].rank < 160
                        ? styles.fine
                        : data.keyStats.homeTeam.offense[index].rank < 200
                        ? styles.neutral
                        : data.keyStats.homeTeam.offense[index].rank < 240
                        ? styles.notGood
                        : data.keyStats.homeTeam.offense[index].rank < 280
                        ? styles.notOkay
                        : data.keyStats.homeTeam.offense[index].rank < 320
                        ? styles.bad
                        : styles.worse
                    }`}
                  >
                    {data.keyStats.homeTeam.offense[index].value ? (
                      <>
                        {data.keyStats.homeTeam.offense[index].value.toFixed(1)}
                        <span className={styles.subText}>
                          {" "}
                          ({data.keyStats.homeTeam.offense[index].rank})
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div
                    className={`${styles.statRight} ${
                      data.keyStats.awayTeam.defense[index].rank === null
                        ? styles.neutral
                        : data.keyStats.awayTeam.defense[index].rank <= 40
                        ? styles.better
                        : data.keyStats.awayTeam.defense[index].rank < 80
                        ? styles.good
                        : data.keyStats.awayTeam.defense[index].rank < 120
                        ? styles.okay
                        : data.keyStats.awayTeam.defense[index].rank < 160
                        ? styles.fine
                        : data.keyStats.awayTeam.defense[index].rank < 200
                        ? styles.neutral
                        : data.keyStats.awayTeam.defense[index].rank < 240
                        ? styles.notGood
                        : data.keyStats.awayTeam.defense[index].rank < 280
                        ? styles.notOkay
                        : data.keyStats.awayTeam.defense[index].rank < 320
                        ? styles.bad
                        : styles.worse
                    }`}
                  >
                    {data.keyStats.awayTeam.defense[index].value ? (
                      <>
                        {data.keyStats.awayTeam.defense[index].value &&
                          data.keyStats.awayTeam.defense[index].value.toFixed(
                            1
                          )}
                        <span className={styles.subText}>
                          {" "}
                          ({data.keyStats.awayTeam.defense[index].rank})
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          id="game-log"
          style={{
            width: "calc(100% - 2px)",
            aspectRatio: "1 / 1",
          }}
        >
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            Game Log Graph
          </div>

          <GameLogChart
            awayCoordinates={[
              ...data.gameLog.awayTeam.map((game) => {
                return {
                  school: data.overview.awayTeam.name,
                  game: game,
                };
              }),
            ]}
            homeCoordinates={[
              ...data.gameLog.homeTeam.map((game) => {
                return {
                  school: data.overview.homeTeam.name,
                  game: game,
                };
              }),
            ]}
            awayId={data.overview.awayTeam.id}
            homeId={data.overview.homeTeam.id}
            awayRank={data.overview.awayTeam.rank}
            homeRank={data.overview.homeTeam.rank}
            awayColor={data.overview.awayTeam.hexCode}
            homeColor={data.overview.homeTeam.hexCode}
            awaySpread={
              data.lineMovement.awayTeam[0]?.spread[
                data.lineMovement.awayTeam[0]?.spread.length - 1
              ]
            }
            homeSpread={
              data.lineMovement.homeTeam[0]?.spread[
                data.lineMovement.homeTeam[0]?.spread.length - 1
              ]
            }
            opponents={data.opponents}
          />
        </div>
        <div id="common-opponents">
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <strong>Recent Form</strong>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.25fr 1fr",
                gridTemplateRows: "repeat(12, 1fr)",
                rowGap: "0.3rem",
              }}
            >
              {data.gameLog.awayTeam
                .slice(-12)
                .reverse()
                .map((game) => {
                  return (
                    <>
                      {/* Individual Team div */}
                      <div
                        className={styles.opponent}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{ height: "30px" }}
                          src={
                            data.opponents[game.oppId].rank
                              ? `https://cbb.vincerix.com/images/teams${
                                  theme === "dark" ? "/dark" : ""
                                }/logo_${game.oppId}.png`
                              : "https://cbb.vincerix.com/images/teams/logo_default.png"
                          }
                          alt={`${data.opponents[game.oppId].name} logo`}
                        />
                        <div className={`${styles.popup} ${styles.right}`}>
                          {data.opponents[game.oppId].name}
                        </div>
                        {data.opponents[game.oppId].rank && (
                          <div
                            style={{
                              position: "absolute",
                              fontSize: "9px",
                              right: "5%",
                              bottom: "0",
                              backgroundColor: "var(--background)",
                              borderRadius: "10px",
                              padding: "0 0.1rem",
                              border: "1px solid",
                            }}
                          >
                            {`#${data.opponents[game.oppId].rank}`}
                          </div>
                        )}
                      </div>

                      <div
                        className={styles.hoverWrapper}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={styles.resultBar}
                          style={{
                            backgroundColor: `${
                              game.points > game.oppPoints
                                ? "#79d279"
                                : "#ff6666ee"
                            }`,
                            width: `${
                              2.5 *
                              Math.min(
                                25,
                                Math.abs(game.points - game.oppPoints)
                              )
                            }%`,
                            height: "75%",
                            justifySelf: "right",
                            borderRadius: "3px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            border: "1px solid #00000055",
                          }}
                        >
                          <div className={`${styles.popup} ${styles.right}`}>
                            <div>{data.opponents[game.oppId].name}</div>
                            <div>
                              {new Date(game.date).toLocaleDateString(
                                undefined,
                                {
                                  month: "numeric",
                                  day: "numeric",
                                  year: "2-digit",
                                }
                              )}
                            </div>
                            <div>
                              {game.isNeutral
                                ? "Neutral"
                                : game.isHome
                                ? "Home"
                                : "Away"}
                            </div>
                          </div>
                        </div>
                        <div style={{ paddingLeft: "4px", fontSize: "small" }}>
                          {`${game.points}-${game.oppPoints}`}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 0.25fr",
                gridTemplateRows: "repeat(8, 1fr)",
                rowGap: "0.3rem",
              }}
            >
              {data.gameLog.homeTeam
                .slice(-12)
                .reverse()
                .map((game) => {
                  return (
                    <>
                      {/* Individual Team div */}
                      <div
                        className={styles.hoverWrapper}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ paddingRight: "4px", fontSize: "small" }}>
                          {`${game.points}-${game.oppPoints}`}
                        </div>
                        <div
                          className={styles.resultBar}
                          style={{
                            backgroundColor: `${
                              game.points > game.oppPoints
                                ? "#79d279"
                                : "#ff6666ee"
                            }`,
                            width: `${
                              2.5 *
                              Math.min(
                                25,
                                Math.abs(game.points - game.oppPoints)
                              )
                            }%`,
                            height: "75%",
                            justifySelf: "right",
                            borderRadius: "3px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            border: "1px solid #00000055",
                          }}
                        >
                          <div className={`${styles.popup} ${styles.left}`}>
                            <div>{data.opponents[game.oppId].name}</div>
                            <div>
                              {new Date(game.date).toLocaleDateString(
                                undefined,
                                {
                                  month: "numeric",
                                  day: "numeric",
                                  year: "2-digit",
                                }
                              )}
                            </div>
                            <div>
                              {game.isNeutral
                                ? "Neutral"
                                : game.isHome
                                ? "Home"
                                : "Away"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.opponent}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{ height: "30px" }}
                          src={
                            data.opponents[game.oppId].rank
                              ? `https://cbb.vincerix.com/images/teams${
                                  theme === "dark" ? "/dark" : ""
                                }/logo_${game.oppId}.png`
                              : "https://cbb.vincerix.com/images/teams/logo_default.png"
                          }
                          alt={`${data.opponents[game.oppId].name} logo`}
                        />
                        <div className={`${styles.popup} ${styles.left}`}>
                          {data.opponents[game.oppId].name}
                        </div>
                        {data.opponents[game.oppId].rank && (
                          <div
                            style={{
                              position: "absolute",
                              fontSize: "9px",
                              left: "5%",
                              bottom: "0",
                              backgroundColor: "var(--background)",
                              borderRadius: "10px",
                              padding: "0 0.1rem",
                              border: "1px solid",
                            }}
                          >
                            {`#${data.opponents[game.oppId].rank}`}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightCol}>
        <div>
          <div
            className={styles.sectionHeader}
            style={{
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            <strong>{isGameFinal ? "Closing Lines" : "Current Lines"}</strong>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                minWidth: "150px",
              }}
            >
              <BookSelector
                options={BOOKS}
                selectedBooks={selectedBooks}
                setSelectedBooks={setSelectedBooks}
              />
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              rowGap: "1rem",
              alignItems: "center",
            }}
          >
            <>
              <div>
                <img
                  style={{ width: "30%" }}
                  src={
                    data.overview.awayTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.awayTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  alt={`${data.overview.homeTeam.name} logo`}
                />
              </div>
              <div></div>
              <div>
                <img
                  style={{ width: "30%" }}
                  src={
                    data.overview.homeTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.homeTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  alt={`${data.overview.homeTeam.name} logo`}
                />
              </div>
            </>
            <div className={styles.bestBookWrapper}>
              {["spread", "moneyline", "total"].map((betType) => (
                <BestBookAvailable
                  key={betType}
                  selectedBooks={selectedBooks}
                  betType={betType}
                  awayOdds={data.lines.awayTeam[betType as keyof GameOdds]}
                  homeOdds={data.lines.homeTeam[betType as keyof GameOdds]}
                  score={
                    isGameFinal
                      ? [
                          +data.overview.awayTeam.total!,
                          +data.overview.homeTeam.total!,
                        ]
                      : []
                  }
                  isVertical={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "calc(100% - 2px)",
          }}
        >
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            Line Movement
          </div>
          <div>
            <form>
              <button
                className={`${styles.coverButton} ${
                  lineMovementTeam.betType === "spread" ? styles.selected : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setLineMovementTeam((old) => {
                    return { ...old, betType: "spread" };
                  });
                }}
              >
                spread
              </button>
              <button
                className={`${styles.coverButton} ${
                  lineMovementTeam.betType === "moneyline"
                    ? styles.selected
                    : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setLineMovementTeam((old) => {
                    return { ...old, betType: "moneyline" };
                  });
                }}
              >
                moneyline
              </button>
              <button
                className={`${styles.coverButton} ${
                  lineMovementTeam.betType === "total" ? styles.selected : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setLineMovementTeam((old) => {
                    return { ...old, betType: "total" };
                  });
                }}
              >
                total
              </button>
            </form>
            {lineMovementTeam.betType !== "total" && (
              <form style={{ marginTop: "0.5rem" }}>
                <button
                  className={`${styles.coverButton} ${
                    lineMovementTeam.team === "awayTeam" ? styles.selected : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setLineMovementTeam((old) => {
                      return { ...old, team: "awayTeam" };
                    });
                  }}
                >
                  {data.overview.awayTeam.name}
                </button>
                <button
                  className={`${styles.coverButton} ${
                    lineMovementTeam.team === "homeTeam" ? styles.selected : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setLineMovementTeam((old) => {
                      return { ...old, team: "homeTeam" };
                    });
                  }}
                >
                  {data.overview.homeTeam.name}
                </button>
              </form>
            )}
          </div>
          <LineMovementChart
            lines={
              lineMovementTeam.betType === "total"
                ? data.lineMovement.homeTeam
                : data.lineMovement[lineMovementTeam.team]
            }
            selectedBooks={selectedBooks}
            betType={lineMovementTeam.betType}
            team={lineMovementTeam.team}
            teamColor={data.overview[lineMovementTeam.team].hexCode}
            isGameFinal={isGameFinal}
          />
        </div>
        <div>
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            Betting Form
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              rowGap: "1rem",
              alignItems: "center",
            }}
          >
            <>
              <div>
                <img
                  style={{ width: "30%" }}
                  src={
                    data.overview.awayTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.awayTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  alt={`${data.overview.homeTeam.name} logo`}
                />
              </div>
              <div></div>
              <div>
                <img
                  style={{ width: "30%" }}
                  src={
                    data.overview.homeTeam.rank
                      ? `https://cbb.vincerix.com/images/teams${
                          theme === "dark" ? "/dark" : ""
                        }/logo_${data.overview.homeTeam.id}.png`
                      : "https://cbb.vincerix.com/images/teams/logo_default.png"
                  }
                  alt={`${data.overview.homeTeam.name} logo`}
                />
              </div>
            </>
            {["ATS", "ML", "TOTAL"].map((betType, index) => {
              return (
                <>
                  {data.bettingForm.awayTeam[index] ? (
                    <>
                      <div>
                        <div>
                          {data.bettingForm.awayTeam[index]?.recent
                            .split("")
                            .map((char, index) => (
                              <ColoredResult key={index} char={char} />
                            ))}
                        </div>
                        <div>{data.bettingForm.awayTeam[index]?.record}</div>
                        <div>
                          Avg:{" "}
                          {oddsToString(
                            data.bettingForm.awayTeam[index]?.aggregate,
                            data.bettingForm.awayTeam[index]?.type
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>-</div>
                  )}
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {betType}
                  </div>
                  {data.bettingForm.homeTeam[index] ? (
                    <>
                      <div>
                        <div>
                          {data.bettingForm.homeTeam[index].recent
                            .split("")
                            .map((char, index) => (
                              <ColoredResult key={index} char={char} />
                            ))}
                        </div>
                        <div>{data.bettingForm.homeTeam[index].record}</div>
                        <div>
                          Avg:{" "}
                          {oddsToString(
                            data.bettingForm.homeTeam[index].aggregate,
                            data.bettingForm.homeTeam[index].type
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>-</div>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div
          style={{
            width: "calc(100% - 2px)",
          }}
          id="cover"
        >
          <div
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              marginBottom: "1rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              fontWeight: "bold",
            }}
          >
            Cover Percentage
          </div>
          <form>
            <button
              className={`${styles.coverButton} ${
                coverType === "spread" ? styles.selected : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCoverType("spread");
              }}
            >
              spread
            </button>
            <button
              className={`${styles.coverButton} ${
                coverType === "moneyline" ? styles.selected : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCoverType("moneyline");
              }}
            >
              moneyline
            </button>
            <button
              className={`${styles.coverButton} ${
                coverType === "total" ? styles.selected : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCoverType("total");
              }}
            >
              over
            </button>
          </form>
          <CoverChart
            awayBuckets={data.bettingHistogram.awayTeam[coverType]}
            awayName={data.overview.awayTeam.name}
            awayHex={data.overview.awayTeam.hexCode}
            homeBuckets={data.bettingHistogram.homeTeam[coverType]}
            homeName={data.overview.homeTeam.name}
            homeHex={data.overview.homeTeam.hexCode}
          />
        </div>
      </div>
    </div>
  );
};

interface SchoolCoordinates {
  school: string;
  game: GameLog;
}

interface GameLogChartProps {
  homeCoordinates: SchoolCoordinates[];
  awayCoordinates: SchoolCoordinates[];
  awayId: number;
  homeId: number;
  awayRank: number;
  homeRank: number;
  awayColor: string;
  homeColor: string;
  awaySpread: number;
  homeSpread: number;
  opponents: Record<string, { name: string; rank: number }>;
}

const GameLogChart: React.FC<GameLogChartProps> = ({
  awayCoordinates,
  homeCoordinates,
  awayId,
  homeId,
  awayColor,
  homeColor,
  awayRank,
  homeRank,
  awaySpread,
  homeSpread,
  opponents,
}) => {
  const { theme } = useTheme();
  const customImage = `https://cbb.vincerix.com/images/teams${
    theme === "dark" ? "/dark" : ""
  }/logo_${awayRank ? awayId : "default"}.png`;
  const image = new Image(28, 28);
  image.src = customImage;
  const homeImageSrc = `https://cbb.vincerix.com/images/teams${
    theme === "dark" ? "/dark" : ""
  }/logo_${homeRank ? homeId : "default"}.png`;
  const homeImage = new Image(28, 28);
  homeImage.src = homeImageSrc;
  const data = {
    responsive: true,
    maintainAspectRation: false,
    datasets: [
      {
        label: awayCoordinates[0].school,
        color: "blue",
        data: awayCoordinates.map((coord, index) => {
          return {
            x: opponents[coord.game.oppId].rank || 363,
            y: Math.min(
              25,
              Math.max(-25, coord.game.points - coord.game.oppPoints)
            ),
            game: coord.game,
            label: `${index}`,
          };
        }),
        pointStyle: image,
        showLine: false,
      },
      {
        label: homeCoordinates[0].school,
        data: homeCoordinates.map((coord, index) => {
          return {
            x: opponents[coord.game.oppId].rank || 363,
            y: Math.min(
              25,
              Math.max(-25, coord.game.points - coord.game.oppPoints)
            ),
            game: coord.game,
            label: `${index}`,
          };
        }),
        pointStyle: homeImage,
        showLine: false,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        align: "end" as const,
        labels: {
          boxWidth: 0,
          font: {
            weight: "bold" as const,
          },
        },
      },
      tooltip: {
        bodyAlign: "center" as const,
        mode: "nearest" as const,
        intersect: false,
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            return [
              new Date(context.raw.game.date).toLocaleDateString(),
              `#${opponents[context.raw.game.oppId].rank || "NR"} ${
                opponents[context.raw.game.oppId].name
              }`,
              "Spread: " +
                (context.raw.game.spread
                  ? (context.raw.game.spread < 0 ? "" : "+") +
                    context.raw.game.spread
                  : "N/A"),
              `${context.raw.game.points}-${context.raw.game.oppPoints}`,
              context.raw.game.isNeutral
                ? "Neutral"
                : context.raw.game.isHome
                ? "Home"
                : "Away",
            ];
          },
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    interaction: {
      intersect: true,
    },
    aspectRatio: 1,
    scales: {
      x: {
        title: {
          text: "Opponent Rank",
          color: "black",
          display: false,
        },
        afterBuildTicks: (axis: { ticks: { value: number }[] }) =>
          (axis.ticks = [1, 60, 120, 240, 300, 362].map((v) => ({
            value: v,
          }))),
        min: 1,
        max: 362,
        reverse: true,
        offset: true,
        position: "center" as const,
      },
      y: {
        title: {
          text: "Margin of Victory",
          color: "black",
          display: false,
        },
        min: -25,
        max: 25,

        ticks: {
          callback: (value: any) =>
            value === 0
              ? null
              : `${
                  value === 25
                    ? "+25+"
                    : value === -25
                    ? "-25+"
                    : value > 0
                    ? `+${value}`
                    : value
                }`,
        },
        offset: true,
        position: "center" as const,
      },
    },
  };

  const quadrants = {
    id: "quadrants",

    beforeDraw(chart: Chart, args: any, options: any) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2;

      // negative lines
      ctx.strokeStyle = "#ff6666ee";
      ctx.moveTo(left, (top + bottom) / 2);
      ctx.lineTo((left + right) / 2, (top + bottom) / 2);
      ctx.lineTo((left + right) / 2, bottom);
      ctx.stroke();

      // positive lines
      ctx.beginPath();
      ctx.strokeStyle = "#79d2797e";
      ctx.moveTo((left + right) / 2, top);
      ctx.lineTo((left + right) / 2, (top + bottom) / 2);
      ctx.lineTo(right, (top + bottom) / 2);
      ctx.stroke();

      // matchup ranks
      if (awayRank) {
        ctx.beginPath();
        ctx.strokeStyle = awayColor;
        ctx.moveTo(x.getPixelForValue(awayRank), y.getPixelForValue(-1));
        ctx.lineTo(x.getPixelForValue(awayRank), y.getPixelForValue(1));
        ctx.stroke();
      }
      if (homeRank) {
        ctx.beginPath();
        ctx.strokeStyle = homeColor;
        ctx.moveTo(x.getPixelForValue(homeRank), y.getPixelForValue(-1));
        ctx.lineTo(x.getPixelForValue(homeRank), y.getPixelForValue(1));
        ctx.stroke();
      }

      // matchup spreads
      if (awaySpread) {
        ctx.beginPath();
        ctx.strokeStyle = awayColor;
        ctx.moveTo(
          x.getPixelForValue(181 - 7.24),
          y.getPixelForValue(-1 * awaySpread)
        );
        ctx.lineTo(
          x.getPixelForValue(181 + 7.24),
          y.getPixelForValue(-1 * awaySpread)
        );
        ctx.stroke();
      }
      if (homeSpread) {
        ctx.beginPath();
        ctx.strokeStyle = homeColor;
        ctx.moveTo(
          x.getPixelForValue(181 - 7.24),
          y.getPixelForValue(-1 * homeSpread)
        );
        ctx.lineTo(
          x.getPixelForValue(181 + 7.24),
          y.getPixelForValue(-1 * homeSpread)
        );
        ctx.stroke();
      }

      // axis labels
      ctx.font = "italic x-small Arial";
      ctx.fillStyle = getComputedStyle(ctx.canvas).getPropertyValue("--color");
      ctx.fillText("RANK", left, y.getPixelForValue(0) - 6);
      ctx.moveTo((left + right) / 2, (top + bottom) / 2);
      ctx.translate(0, 0);
      ctx.rotate(Math.PI / 2);
      ctx.textAlign = "right";
      ctx.fillText("M.O.V.", bottom, -x.getPixelForValue(181) - 6);

      ctx.restore();
    },
  };

  return <Scatter data={data} options={options} plugins={[quadrants]} />;
};

interface CoverChartProps {
  awayBuckets: HistogramBucket[];
  awayName: string;
  awayHex: string;
  homeBuckets: HistogramBucket[];
  homeName: string;
  homeHex: string;
}
const CoverChart: React.FC<CoverChartProps> = ({
  awayBuckets,
  homeBuckets,
  awayName,
  awayHex,
  homeName,
  homeHex,
}) => {
  const maxGames = Math.max(
    ...[...awayBuckets, ...homeBuckets].map((bucket) => bucket.games)
  );
  const biggerBuckets =
    awayBuckets.length >= homeBuckets.length ? awayBuckets : homeBuckets;
  const data = {
    responsive: true,
    maintainAspectRation: false,
    labels: biggerBuckets.map((bucket) => bucket.bucket),
    datasets: [
      {
        label: `${awayName} Covers`,
        data: awayBuckets.map((bucket) => bucket.wins),
        backgroundColor: awayHex,
        xAxisID: "percentage",
        barPercentage: 0.7,
        stack: "Away",
        borderRadius: 6,
      },
      {
        label: `${awayName} Games`,
        data: awayBuckets.map((bucket) => bucket.games),
        borderWidth: 2,
        borderColor: "rgb(155, 155, 155)",
        backgroundColor: "white",
        stack: "Away",
      },
      {
        label: `${homeName} Covers`,
        data: homeBuckets.map((bucket) => bucket.wins),
        backgroundColor: homeHex,
        stack: "Home",
        xAxisID: "percentage",
        barPercentage: 0.7,
        borderRadius: 6,
      },
      {
        label: `${homeName} Games`,
        data: homeBuckets.map((bucket) => bucket.games),
        borderWidth: 2,
        borderColor: "rgb(155, 155, 155)",
        backgroundColor: "white",
        stack: "Home",
      },
    ],
  };
  const options = {
    indexAxis: "y" as const,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index" as const,
        axis: "y" as const,
        intersect: false,
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        stacked: true,
        min: 0,
        max: maxGames,
        ticks: {
          stepSize: 1,
        },
      },
      percentage: {
        display: false,
        beginAtZero: true,
        position: "top" as const,
        type: "linear" as const,
        min: 0,
        max: maxGames,
        stacked: true,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false,
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
};

interface LineMovementChartProps {
  lines: LineMovement[];
  selectedBooks: string[];
  betType: "spread" | "moneyline" | "total";
  team: "awayTeam" | "homeTeam";
  teamColor: string;
  isGameFinal: boolean;
}
const LineMovementChart: React.FC<LineMovementChartProps> = ({
  lines,
  selectedBooks,
  betType,
  team,
  teamColor,
  isGameFinal,
}) => {
  const consensusColor = betType === "total" ? "gray" : teamColor;
  const filteredLines = lines.filter((bookMovement) =>
    ["Consensus", ...selectedBooks].includes(bookMovement.book)
  );
  const data: ChartData<"line"> = {
    labels: filteredLines[0]?.[betType].map((_, index) =>
      index === filteredLines[0][betType].length - 1
        ? isGameFinal
          ? "Close"
          : "Now"
        : `-${2 * (filteredLines[0][betType].length - index - 1)}hr`
    ),
    datasets: [
      ...filteredLines.map((bookMovement) => {
        const baseData = {
          label: bookMovement.book,
          backgroundColor: BOOKS.find((book) => book.name === bookMovement.book)
            ? BOOKS.find((book) => book.name === bookMovement.book)?.color
            : consensusColor,
          data: bookMovement[betType],
          borderWidth: bookMovement.book === "Consensus" ? 3 : 2,
          borderColor: BOOKS.find((book) => book.name === bookMovement.book)
            ? BOOKS.find((book) => book.name === bookMovement.book)?.color
            : consensusColor,
          borderDash: bookMovement.book !== "Consensus" ? [10, 5] : [],
          pointBackgroundColor: BOOKS.find(
            (book) => book.name === bookMovement.book
          )
            ? BOOKS.find((book) => book.name === bookMovement.book)?.color
            : consensusColor,
          pointRadius: bookMovement.book === "Consensus" ? 3 : 2,
          hidden: !(
            bookMovement.book === "Consensus" ||
            BOOKS.find((book) => book.name === bookMovement.book)?.major
          ),
        };
        return baseData;
      }),
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        mode: "index" as const,
        intersect: false,
        callbacks: {
          label: (context: any) => {
            const val =
              betType !== "total" && context.raw > 0
                ? `+${context.raw}`
                : context.raw;
            return `${context.dataset.label}: ${val}`;
          },
        },
      },
    },
    datasets: {
      line: {
        borderCapStyle: "round" as const,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: betType !== "moneyline" ? 0.5 : undefined,
          callback: (value: any) =>
            betType !== "total" && value > 0 ? `+${value}` : value,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default DetailedMatchup;
