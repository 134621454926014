import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { useTheme } from "../../contexts/ThemeContext";

const Header = () => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.headerContainer} headerContainer`}>
      <a href="/" className={styles.logoLink}>
        <img src={`/images/vincerix-${theme}-small.png`} alt="vincerix" />
      </a>
      <div className={styles.nav}>
        <Link style={{ color: "inherit" }} to={"/"}>
          matchups
        </Link>
        <Link style={{ color: "inherit" }} to={"/stats"}>
          stats
        </Link>
      </div>
    </div>
  );
};

export default Header;
